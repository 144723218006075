import cls from 'classnames';
import { GeoJSONSource, MapLayerMouseEvent } from 'mapbox-gl';
import { useTranslation } from 'next-i18next';
import * as React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import Map, { Layer, MapRef, Popup, Source } from 'react-map-gl';
import NumberFormat from 'react-number-format';
import { PropertyTypeGroup } from 'shared/dist/types';
import { getGroupForObjectType } from 'shared/dist/util/calculations';

interface Property {
  lng: number;
  lat: number;
  uuid: string;
  custom_name: string;
  property_key: string;
  area_total: number;
  county: string;
  primaryPropType?: string;
}

interface DashboardMapProps {
  propertyList: Property[];
}

export default function DashboardMap({ propertyList }: DashboardMapProps) {
  const [cursor, setCursor] = useState<string>('grab');
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);
  const mapRef = useRef<MapRef>(null); // Reference to the Map component

  const { t } = useTranslation('common');

  // Create a geojson feature for each property
  const geojsonData: GeoJSON.FeatureCollection<GeoJSON.Geometry> = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: propertyList.map((property) => ({
        type: 'Feature',
        properties: { ...property },
        geometry: {
          type: 'Point',
          coordinates: [property.lng, property.lat]
        }
      }))
    };
  }, [propertyList]);

  // Handle cluster click to zoom in and show its children
  const handleClusterClick = (e: MapLayerMouseEvent) => {
    const feature = e.features?.[0];
    const clusterId = feature?.properties?.cluster_id;
    const mapboxSource = mapRef.current?.getSource('properties') as GeoJSONSource;

    if (mapboxSource && clusterId !== undefined) {
      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;

        mapRef.current?.easeTo({
          center: (feature?.geometry as GeoJSON.Point).coordinates as [number, number],
          zoom: zoom ?? undefined,
          duration: 500
        });
      });
    }
  };

  // Handle individual property marker click to show popup
  const handlePropertyClick = (e: MapLayerMouseEvent) => {
    const clickedFeature = e.features?.[0];
    const property = clickedFeature?.properties;

    if (property) {
      setSelectedProperty({
        ...(property as Property),
        primaryPropType: getGroupForObjectType(
          property?.tmpTaxCode ? Number(property?.tmpTaxCode) : 0
        )
      });
    }
  };

  const handleMapClick = useCallback((event: MapLayerMouseEvent) => {
    const feature = event.features && event.features[0];
    setSelectedProperty(null);

    switch (feature?.layer?.id) {
      case 'clusters':
        handleClusterClick(event);
        break;
      case 'unclustered-point':
        handlePropertyClick(event);
        break;
      default:
        return;
    }
  }, []);

  const handleMouseDown = useCallback((event: MapLayerMouseEvent) => {
    const feature = event.features && event.features[0];
    setSelectedProperty(null);
    if (!feature) {
      setCursor('grabbing');
    }
  }, []);

  const handleMouseUp = useCallback((event: MapLayerMouseEvent) => {
    const feature = event.features && event.features[0];
    if (!feature) {
      setCursor('grab');
    }
  }, []);

  return (
    <div className="w-full h-[400px] rounded-t-md">
      <Map
        ref={mapRef}
        mapboxAccessToken={`pk.eyJ1IjoiYm9ib2xhZ2V0IiwiYSI6ImNrZmNmZmtlajE4NG0zNm81NjB5ZTgwOGwifQ.AqhIm23pQPp2jjs_Q2Ca6Q`}
        cursor={cursor}
        onClick={handleMapClick}
        interactiveLayerIds={['clusters', 'unclustered-point']}
        onMouseEnter={useCallback(() => setCursor('pointer'), [])}
        onMouseLeave={useCallback(() => setCursor('grab'), [])}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        initialViewState={{
          longitude: propertyList[0]?.lng || -122.4, // Default to first property or fallback
          latitude: propertyList[0]?.lat || 37.8,
          zoom: 4
        }}
        style={{ width: '100%', height: '100%' }}
        mapStyle={`mapbox://styles/bobolaget/cm11shb1501de01qo0pdqh21a`}>
        {/* Source to manage clustering */}
        <Source
          id="properties"
          type="geojson"
          data={geojsonData}
          cluster={true}
          clusterMaxZoom={14} // Max zoom to cluster points
          clusterRadius={50} // Cluster radius in pixels
        >
          {/* Layer for clusters */}
          <Layer
            id="clusters"
            type="circle"
            source="properties"
            filter={['has', 'point_count']}
            paint={{
              'circle-color': [
                'step',
                ['get', 'point_count'],
                '#51bbd6', // Blue for small clusters
                100,
                '#f1f075', // Yellow for medium clusters
                750,
                '#f28cb1' // Pink for large clusters
              ],
              'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
            }}
          />

          {/* Layer for cluster count labels */}
          <Layer
            id="cluster-count"
            type="symbol"
            source="properties"
            filter={['has', 'point_count']}
            layout={{
              'text-field': '{point_count_abbreviated}',
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 12
            }}
          />

          {/* Layer for individual property markers */}
          <Layer
            id="unclustered-point"
            type="circle"
            source="properties"
            filter={['!', ['has', 'point_count']]}
            paint={{
              'circle-color': '#11b4da', // Distinct blue color for properties
              'circle-radius': 10,
              'circle-stroke-width': 2,
              'circle-stroke-color': '#fff' // White border for property markers
            }}
          />
        </Source>

        {/* Popup for selected property */}
        {selectedProperty && (
          <Popup
            longitude={selectedProperty.lng}
            latitude={selectedProperty.lat}
            closeButton={false}
            anchor="top">
            <div className="shadow-xl rounded-md overflow-hidden bg-white dark:bg-dark-faded w-max min-w-[240px] max-w-[340px] text-xs flex flex-col">
              <div
                className={cls(
                  'bg-gradient-to-b to-white dark:to-dark-faded p-4 pb-0',
                  {
                    'from-bobo-typespacelight':
                      selectedProperty.primaryPropType === PropertyTypeGroup.SPACE
                  },
                  {
                    'from-bobo-typewarehouselight':
                      selectedProperty.primaryPropType === PropertyTypeGroup.WAREHOUSE
                  },
                  {
                    'from-bobo-typeresidentiallight':
                      selectedProperty.primaryPropType === PropertyTypeGroup.HOUSING
                  },
                  {
                    'from-bobo-typeindustrylight':
                      selectedProperty.primaryPropType === PropertyTypeGroup.INDUSTRY
                  },
                  {
                    'from-bobo-typestreetlight':
                      selectedProperty.primaryPropType === PropertyTypeGroup.SPECIAL
                  },
                  {
                    'from-bobo-typestreetlight':
                      selectedProperty.primaryPropType === PropertyTypeGroup.STREET
                  }
                )}>
                <div className="flex justify-between w-full items-start">
                  <div>
                    <div className="font-medium text-sm leading-3">
                      {selectedProperty.custom_name}
                    </div>

                    <div className="text-mini leading-3 text-gray-400 dark:text-gray-400">
                      {selectedProperty.county}
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-b border-gray-200 dark:border-gray-500 my-2 mx-4" />

              <div className="grid grid-cols-2 mx-4 mb-3">
                <p className="font-demi">{t('property.key')}</p>
                <p className="text-right">{selectedProperty.property_key}</p>
                <p className="font-demi">{t('value-calculator.area')}</p>
                <NumberFormat
                  thousandSeparator={' '}
                  displayType="text"
                  value={selectedProperty.area_total}
                  suffix={t('general.square-meters')}
                  className="text-right"
                />
              </div>
            </div>
          </Popup>
        )}
      </Map>
    </div>
  );
}
