import { useUser, withPageAuthRequired } from '@auth0/nextjs-auth0';
import cls from 'classnames';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { useGlobal } from 'reactn';
import { Actor } from 'shared/dist/types';
import Container from 'src/layout/container';

import { makeStaticProps } from '@/src/api/i18nUtil';
import DashboardIndex from '@/src/components/dashboard/overview';
import LoadingModal from '@/src/widgets/loading-dialog';

export default withPageAuthRequired(function IndexHome() {
  const { isLoading } = useUser();
  const [actor] = useGlobal<{ activeActor: Actor }>('activeActor' as never);

  const { t } = useTranslation('common');

  if (isLoading) {
    return (
      <Container>
        <div>{t('general.loading')}</div>
      </Container>
    );
  }

  if (!actor) {
    return <Container />;
  }

  const mod: JSX.Element = <DashboardIndex />;
  const mapOnly = true;

  return (
    <Container
      section={undefined}
      header={true}
      tabsRight={null}
      hideTabMenu={true}
      subTitle={<span>&nbsp;</span>}
      mapOnly={mapOnly}
      bgClass="!bg-gray-50 dark:!bg-dark-lessfaded"
      subHeader={false}>
      <Head>
        <title></title>
      </Head>

      <div>
        <div className={cls('relative')}>
          {!actor || isLoading ? <LoadingModal isLoading={true} /> : mod}
        </div>
      </div>
    </Container>
  );
});

export async function getStaticProps({ locale }) {
  return makeStaticProps(locale, ['common', 'shared']);
}
